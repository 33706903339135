.category-container {
    margin-left: -20px;
    width: calc(100% + 30px) !important;
}
.rec-carousel-item {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
}
.card-body {
    display: flex;
    flex-direction: column;
}
