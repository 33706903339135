.appContent-Container {
    flex-direction: column;
    flex: 1;
    padding: 20px;
    margin-left: 80px;
    /*overflow: hidden;*/
}
.appContent-Container .card-text {
    font-size: 0.8rem;
}
