* {
  font-family: 'sharp-sans';
  color: #4F657A;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.button--red,
.button--red:hover{
  color: #FFFFFF;
  background: #E3000B;
  border: 1px solid #E3000B;
  margin-top: auto;
  font-family: 'sharp-sans';
}
.button--red--outline,
.button--red--outline:hover{
  color: #E3000B;
  background: #FFFFFF;
  border: 1px solid #E3000B;
  margin-top: auto;
  font-family: 'sharp-sans';
}
.button--red--square,
.button--red--square:hover {
   color: #FFFFFF;
   background: #E3000B;
   border: 1px solid #E3000B;
   margin-top: auto;
   font-family: 'sharp-sans';
   width: 45px;
   height: 45px;
 }
.button--green,
.button--green:hover{
  color: #FFFFFF;
  background: #00A5AA;
  border: 1px solid #00A5AA;
  margin-top: auto;
  font-family: 'sharp-sans';
}
.button--green--outline,
.button--green--outline:hover{
  color: #00A5AA;
  background: #FFFFFF;
  border: 1px solid #00A5AA;
  margin-top: auto;
  font-family: 'sharp-sans';
}
.button--green--square,
.button--green--square:hover {
  color: #FFFFFF;
  background: #00A5AA;
  border: 1px solid #00A5AA;
  margin-top: auto;
  font-family: 'sharp-sans';
  width: 45px;
  height: 45px;
}
.appContent-Container {
  width: calc(100% - 80px);
}


@font-face{
  font-family:'sharp-sans';
  src:local('sharp-sans'), url(assets/fonts/sharpsans-bold.woff2) format("woff");
  font-weight:700;font-style:normal
}

@font-face{
  font-family:'sharp-sans';
  src:local('sharp-sans'), url(assets/fonts/sharpsans-medium.woff2) format("woff");
  font-weight:500;font-style:normal
}
@font-face{
  font-family:'sharp-sans';
  src:local('sharp-sans'), url(assets/fonts/sharpsans-semibolditalic.woff2) format("woff");
  font-weight:600;font-style:italic
}
@font-face{
  font-family:'sharp-sans';
  src:local('sharp-sans'), url(assets/fonts/sharpsans-semibold.woff2) format("woff")
}

.page-description {
  width: 80%;
  margin: auto auto;
  font-size: 14px;
  padding-bottom: 25px
}
.page-title {
  font-size: 28px;
  font-weight: 600;
}
.product-title {
  font-size: 14px;
  font-weight: 600;
}
.welcome-product-title {
  font-size: 20px;
  font-weight: 600;
}
section .card-img, .card-img-bottom, .card-img-top {
  width: 80%;
  align-self: center;
}
section .col-3 a {
  text-decoration: none;
}
.card {
  width: auto !important;
}
.explore-button {
  font-size: 12px;
  font-weight: 600;
}
.product-attributes {
  font-size: 12px;
}
.product-heading-title {
  font-size: 32px;
}
.product-listing {
  margin-top: 15px;
  font-size: 12px;
}
.product-color-title {
  font-size: 14px;
  font-weight: 600;
}
.color-name {
  font-size: 12px;
}
