.backButton-container {
    position: absolute;
    top: 25px;
    right: 25px;
}
.backButton-container.product-page {
    display: flex;
    margin-left: auto;
    position: relative;
}

