.pro-icon-wrapper {
    height: 60px !important;
    line-height: inherit !important;
}
.pro-icon {
    flex-direction: column;
}
.pro-icon span {
    font-size: 12px;
}

