.hero__container {
    position: relative;
}
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}
.title {
    paddingBottom: 10px;
}
.content-area {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: #fff;
    margin-left: 50px;
}

.list {
    list-style: none;
    column-count: 2;
    margin: 2.5rem 0 0;
    padding: 0;
}
.list__item {
    page-break-inside: avoid;
    break-inside: avoid;
}
.list__href {
    display: block;
    width: 100%;
    font-weight: 700;
    padding: 0 1.75rem 0.9375rem 0;
    position: relative;
    border-bottom: 1px solid #DEDEDF;
    text-decoration: none;
    color: black;
}
.list__href svg {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 15px;
    margin: auto;
    color: black;
}


