.d-flex.flex-column > div > a {
    width: 100%;
}
.icon-position {
    margin-right: 15px;
    margin-top: -1px;
}
.filterButton  {
    min-width: 200px;
    font-size: 12px;
    font-weight: 600;
}
