.gridlayout-container .col {
    display: flex;
    justify-content: center;
    /*justify-content: flex-start;*/
}
.gridlayout-container .col a {
    text-decoration: none;
}
.scrollable-div {
    height: 100%;
    overflow: auto;
    text-align: left;
}
.scrollable-div::-webkit-scrollbar {
    width: 5px;
}
.scrollable-div::-webkit-scrollbar-track {
    background: #F7F8F9;
    border-radius: 5px;
}
.scrollable-div::-webkit-scrollbar-thumb {
    background: #e3000b;
    border-radius: 5px;
}
.scrollable-div::-webkit-scrollbar-thumb:hover {
    background: #555;
}
