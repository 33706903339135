section .pro-sidebar {
    position: fixed !important;
    width: 350px;
}
.pro-sidebar-layout {
    border-right: 1px solid #00A5AA;
}
.pro-sidebar,
.pro-inner-item {
    color: black !important;
    font-weight: 800;
}
.pro-sidebar.collapsed .pro-sidebar-footer,
.pro-sidebar.collapsed .pro-sidebar-header {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.pro-sidebar > .pro-sidebar-inner {
    background-color: #f7f8f9 !important;
}
.pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper,
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
    background-color: transparent !important;
}
.pro-sidebar:not(.collapsed) .pro-sidebar-footer {
    height: 80px;
    display: flex;
    justify-content: right;
    align-items: center;
    padding-right: 25px;
}
.pro-sidebar:not(.collapsed) .pro-sidebar-header {
    height: 80px;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 25px;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
    font-size: 22px;
    padding-left: 15px;
}
.react-slidedown.pro-inner-list-item .pro-menu-item {
    border-bottom: none;
}
.react-slidedown.pro-inner-list-item .pro-menu-item > .pro-inner-item {
    padding-left: 55px !important;
}
.react-slidedown.pro-inner-list-item .pro-menu-item > .pro-inner-item > .pro-item-content {
    font-size: 1em;
    font-weight: 300;
    border-bottom: none !important;
}
.pro-sidebar .pro-menu a {
    color: #4F657A !important;
}
.pro-icon-wrapper {
    height: 60px !important;
    line-height: inherit !important;
}
.pro-icon {
    flex-direction: column;
}
.pro-icon span {
    font-size: 12px;
}
.pro-sidebar:not(.collapsed) nav.pro-menu.shaped.square.inner-submenu-arrows:not(:last-child) {
    border-bottom: 1px solid lightgray;

}
.pro-sidebar:not(.collapsed) nav.pro-menu.shaped.square.inner-submenu-arrows {
    margin: 0 25px;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper {
    right: 0px !important;
}
section .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu.open > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
    transform: rotate(90deg);
}
section .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
    background: url('../../assets/images/arrow-right.svg') no-repeat top left;
    height: 19px;
    width: 18px;
    background-size: 19px;
    fill: rgb(227, 0, 11);
    border: none !important;
    border-width: 0px;
    transform: rotate(-0deg);
    padding: 0 0 0 5px;
}
section:not(.collapsed) .pro-sidebar-footer {
    min-height: 80px;
}

