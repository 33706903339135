.colorSwatch-container {
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
}
.colorSwatch-container .color-name {

}
.colorSwatch-container .color-swatch {
    height: 45px;
    width: 45px;
    border-radius: 25%;
    display: inline-block;
    background-color: #0a58ca;
    border: 1px solid lightgray;
}
.colorSwatch-container .product-code {
 font-size: 10px;
    font-weight: 600;
}
section .colour-container {
    padding: 0px;
}
