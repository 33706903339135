.scrollable-div {
    height: 100%;
    overflow: auto;
    text-align: justify;
}
.scrollable-div::-webkit-scrollbar {
    width: 5px;
}
.scrollable-div::-webkit-scrollbar-track {
    background: #F7F8F9;
    border-radius: 5px;
}
.scrollable-div::-webkit-scrollbar-thumb {
    background: #e3000b;
    border-radius: 5px;
}
.scrollable-div::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.vh-85 {
    height: 85vh!important;
}
section .table th,
section .table td {
    padding: 5px 0px;
    border: none;
}
